// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function iframe(src, param) {
  var view = buildView({title: param.title, modal: true});
  var frame = $('<iframe src="' + src + '"></iframe>')
    // FIXME dim is undefined
    // eslint-disable-next-line no-undef
    .height(dim.height)
    .appendTo(view.main);

  if (param.return)
    var interval = setInterval(function () {
      try {
        var search = frame[0].contentWindow.document.location.search.slice(1);
        if (!search) return;
        search = new URLSearchParams(search);
        search = Object.fromEntries(search.entries());
        param.send = Object.assign(param.send, search);
        ajax(param);
        clearInterval(interval);
      } catch (e) {
        console.error(e);
      }
    }, 100);
}
